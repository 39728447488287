import React from 'react';

import PageLayout from '../../views/Layout/Page';
import TripsHistory from '../../views/Trip/TripsHistory';

export default function TripsHistoryPage() {
  return (
    <PageLayout pageview="my_trips_history" private>
      <TripsHistory />
    </PageLayout>
  );
}
