import React from 'react';

import { TripsGroup } from '../../model/Trip';
import RouteEnum from '../../RouteEnum';
import DotIcon from '../DotIcon';
import Link from '../Link';
import MetaTags from '../MetaTags';
import T from '../Translate';

import TripsContainer from './TripsContainer';

export default function TripsHistory() {
  return (
    <main className="main-container container container--top my-5 pb-5">
      <MetaTags title="trips.history.meta.title" />

      <div className="row mb-3">
        <div className="col">
          <Link className="text-muted" to={RouteEnum.TRIPS}>
            <i className="icon icon-arrow-back" />
          </Link>
        </div>
      </div>

      <h1 className="display-1 mb-5">
        <T id="trips.history.title" />
      </h1>
      <TripsContainer tripGroup={TripsGroup.FINISHED}>
        <DotIcon icon="car-key" text={<T id="trips.history.empty" />} />
        <br />
        <div className="text-center">
          <Link className="btn btn-primary btn-lg px-5" to={RouteEnum.RENT_CAR}>
            <i className="icon icon-magnifier mr-2" />
            <T id="trips.history.findCar" />
          </Link>
        </div>
      </TripsContainer>
    </main>
  );
}
